import { Progress } from '@atoms/Progress'
import clsx from 'clsx'

type ProgressBarProps = {
  progress: number,
}

export function ProgressBar({ progress }: ProgressBarProps) {
  let progressColor: string = 'tw-bg-gray--blue-100'

  if (progress > 0 && progress <= 49) {
    progressColor = 'tw-bg-red-600'
  } else if (progress > 49 && progress <= 80) {
    progressColor = 'tw-bg-yellow-500'
  } else if (progress > 80 && progress < 100) {
    progressColor = 'tw-bg-green-600'
  } else if (progress === 100) {
    progressColor = 'tw-bg-primary-500'
  }

  return (
    <Progress 
      value={progress} 
      progressColor={progressColor}
      className={clsx("tw-w-full tw-bg-gray-blue-100")}
      data-testid={`progress-bar-percentage-${progress.toFixed(2)}`}
    />
  )
}
