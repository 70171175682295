import useMutation from '@hooks/useGQLMutation'
import { useErrorsStore, type ErrorType } from '@stores/errorsStore'

import { scopedTranslation } from '@utils/I18n'

import { assignShiftSlotMutation } from './mutations'
import type { AssignShiftSlotDocument } from '@frontend/graphql/types.generated'

const t = scopedTranslation('components.assign_shift')

type AssignShiftSlotInput = {
  id: string
  supporterId: string
  onSuccess: () => void
}

const useAssignShiftSlotMutation = () => {
  const { setErrors: setAppErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof AssignShiftSlotDocument>(assignShiftSlotMutation)

  const assignShiftSlot = async ({ id, supporterId, onSuccess }: AssignShiftSlotInput) => {
    clearErrors('assignShift')
    mutate(
      {
        input: {
          id: String(id),
          supporterId: supporterId,
        },
      },
      {
        onSuccess: (response) => {
          if (response?.assignShiftSlot?.shiftSlot) {
            onSuccess()
          } else {
            setAppErrors({
              errorsPath: 'assignShiftSlot',
              errors: response?.assignShiftSlot?.errors ?? [
                {
                  code: 'ASSIGN_SHIFT_ERROR',
                  message: t('errors.unable_to_submit_shift_error'),
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'assignShiftSlot',
            errors: [
              {
                code: 'ASSIGN_SHIFT_ERROR',
                message: t('errors.unable_to_submit_shift_error'),
              },
            ],
          })
        },
      }
    )
  }

  return assignShiftSlot
}

export default useAssignShiftSlotMutation
