import React from 'react'

type PageHeaderProps = {
  heading: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ heading }) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-4 tw-pb-6">
      <h1 className="tw-font-bold tw-text-xl tw-text-primary-600">{heading}</h1>
      <div className="tw-flex-grow tw-mt-1 tw-relative tw-left-0">
        <hr className="tw-border-t tw-border-primary-600" />
      </div>
    </div>
  )
}

export default PageHeader