import React from 'react'
import { create } from 'zustand'

import type { Event, ShiftConnection, Roster } from '@frontend/graphql/types.generated'
import timeFormatter from '@frontend/utils/timeFormatter'

import ConfirmDraftRosterDialog from '../features/confirm_shifts/ConfirmDraftRosterDialog'
import { ShiftsTable, ShiftWithSlots } from '../features/shifts/ShiftsTable'
import { Link } from '@atoms'

type ShiftsStore = {
  groupId: string
  setGroupId: (groupId: string) => void
}

export const useShiftsStore = create<ShiftsStore>((set) => ({
  groupId: '',
  setGroupId: (groupId) => set({ groupId }),
}))

const EventDetails = ({ event }: { event: Pick<Event, 'name' | 'startTime' | 'endTime'> }) => {
  const eventStartDate = timeFormatter.fromISO(event.startTime).toDate()
  const eventStartTime = timeFormatter.fromISO(event.startTime).toHoursMinutes()
  const eventEndTime = timeFormatter.fromISO(event.endTime).toHoursMinutes()

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <h1 className="tw-text-xl tw-font-medium">{event.name}</h1>
      <h1 className="tw-text-xl tw-font-medium">{eventStartDate}</h1>
      <h1 className="tw-text-xl tw-font-medium">
        {eventStartTime} - {eventEndTime}
      </h1>
    </div>
  )
}

type ShiftsIndexProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime'> & {
    roster:
      | (Pick<Roster, 'id'> & {
          shifts?: Pick<ShiftConnection, 'totalCount'> & {
            nodes: Array<ShiftWithSlots> | []
          }
        })
      | null
  }
  groupId: string
}

const ShiftsIndex = ({ event, groupId }: ShiftsIndexProps) => {
  const setGroupId = useShiftsStore((state) => state.setGroupId)
  setGroupId(groupId)

  return (
    <div className="tw-px-4 tw-flex tw-flex-col tw-gap-6 tw-min-w-[80vw] sm:tw-pl-8">
      <div>
        <Link
          rank="link"
          leadingIcon="chevron-left"
          href={`/supporter_groups/${groupId}/events/${event.id}`}
        >{`Back to "${event.name}" event`}</Link>
      </div>
      <div className="tw-flex tw-items-center tw-gap-4">
        <h1 className="tw-text-display-sm tw-font-bold tw-text-primary-600">Shifts</h1>
        <div className="tw-flex-grow tw-mt-1 tw-relative tw-left-0 ">
          <hr className="tw-border-t tw-border-primary-600" />
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-py-6 tw-gap-8">
        <EventDetails event={event} />
        <ConfirmDraftRosterDialog event={event} />
        <ShiftsTable event={event} />
      </div>
    </div>
  )
}

export default ShiftsIndex;
