// Leave React here since this file is used in v1.5
import React, { createContext, useContext, type ReactNode } from 'react'
import { GraphQLClient } from 'graphql-request'

export const GraphQLClientContext = createContext<GraphQLClient | null>(null)

export function useGraphQLClient(): GraphQLClient {
  const context = useContext(GraphQLClientContext)

  if (!context) {
    throw new Error('useGraphQLClient must be used within a GraphQLClientProvider')
  }

  return context
}

interface GraphQLClientProviderProps {
  children: ReactNode
}
export function GraphQLClientProvider({ children }: GraphQLClientProviderProps) {
  const csrfToken = (document.querySelector('meta[name=csrf-token]') as HTMLMetaElement)?.content
  const client = new GraphQLClient('/graphql', {
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  })

  return <GraphQLClientContext.Provider value={client}>{children}</GraphQLClientContext.Provider>
}
