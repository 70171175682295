import { useSearchParams } from '@frontend/hooks/useSearchParams'

type SortQueryData = {
  sort_by: string
}

type QueryData = {
  sort_by?: string
}

type UseEventsSearchProps = {
  currentSort: string,
  setLoading: (isLoading: boolean) => void
}

export const sortAdapter = (sort: string): SortQueryData => {
  return {
    sort_by: sort,
  }
}

function useEventsSearch({ currentSort, setLoading }: UseEventsSearchProps) {
  const sortQuery = sortAdapter(currentSort)

  const queryData: QueryData = {
    ...sortQuery,
  }

  useSearchParams<QueryData>({
    queryData,
    partialReloadKeys: ['events', 'activeSearch'],
    setLoading,
  })
}

export default useEventsSearch