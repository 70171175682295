import React, { useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import AsyncSelect from '@components/molecules/AsyncSelect'
import { scopedTranslation } from '@utils/I18n'

import { useShiftsStore } from '@frontend/contexts/events_management/pages/ShiftsIndex'
import useSupporterSearchQuery from './useSupporterSearchQuery'

export type SupporterOption = {
  id: string
  value: string
  label: string
}

type SupporterSelectProps = {
  field: ControllerRenderProps<any, 'supporterOption'>
}

const t = scopedTranslation('components.assign_shift')

export const SupporterSelect = ({ field }: SupporterSelectProps) => {
  const [totalCount, setTotalCount] = useState<number>(0)
  const { querySupporter } = useSupporterSearchQuery()
  const groupId = useShiftsStore((state) => state.groupId)

  const loadOptions = async (inputValue: string): Promise<SupporterOption[]> => {
    const results = await querySupporter({ nameInput: inputValue, groupId: groupId })
    setTotalCount(results['totalCount'])
    return results['supporters']
  }

  return (
    <AsyncSelect
      displayName={t('search_label')}
      placeholder={t('search_placeholder')}
      field={field}
      isCreatable={false}
      isMulti={false}
      loadOptions={loadOptions}
      totalCount={totalCount}
      defaultOptions={true}
      noOptionsMessage={t('search_no_results')}
    />
  )
}

export default SupporterSelect
