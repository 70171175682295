import gql from 'graphql-tag'

export const assignShiftSlotMutation = gql`
  mutation assignShiftSlot($input: AssignShiftSlotInput!) {
    assignShiftSlot(input: $input) {
      shiftSlot {
        id
        supporter {
          id
          firstName
          fullName
        }
      }
      success
      errors {
        code
        field
        message
        solution
      }
    }
  }
`
