import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useFormWithErrors from '@frontend/hooks/useFormWithErrors'
import type { ShiftInput } from '@frontend/graphql/types.generated'

import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import { Dialog, Button, Alert } from '@atoms'
import { scopedTranslation, i18nValidation } from '@utils/I18n'
import Icon from '@components/utils/Icon'
import timeFormatter from '@frontend/utils/timeFormatter'

import { EditShiftFieldRow } from './EditShiftFieldRow'
import { useEditShiftMutation } from './useEditShiftMutation'
import type { ShiftWithSlots } from '../shifts/ShiftsTable'

const t = scopedTranslation('components.edit_shift')
const tAttributes = scopedTranslation('attributes.shift')

export type EditShiftDialogProps = {
  shift: ShiftWithSlots
  onShiftUpdate: () => void
}

export const EditShiftDialog = ({ shift, onShiftUpdate }: EditShiftDialogProps) => {
  const [open, setOpen] = React.useState(false)

  const shiftSchema = yup.object({
    startTime: yup.string().label(tAttributes('start_time')).required(i18nValidation('required')),
    endTime: yup.string().label(tAttributes('end_time')).required(i18nValidation('required')),
    roleName: yup.string().label(tAttributes('role')).required(i18nValidation('required')),
    volunteersRequired: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .label(tAttributes('volunteers'))
      .min(
        1,
        i18nValidation('positive_number', 'one', {
          transformLabel: (label) => label.toLowerCase(),
        })
      )
      .required(
        i18nValidation('positive_number', 'one', {
          transformLabel: (label) => label.toLowerCase(),
        })
      ),
  })

  const onSuccess = React.useCallback(() => {
    setOpen(false)
    onShiftUpdate()
  }, [onShiftUpdate])

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setErrors: setFormErrors,
  } = useFormWithErrors({
    resolver: yupResolver(shiftSchema),
    mode: 'onTouched',
    defaultValues: {
      startTime: timeFormatter.fromISO(shift.startTime).to24hours(),
      endTime: timeFormatter.fromISO(shift.endTime).to24hours(),
      roleName: shift.roleName,
      volunteersRequired: shift.shiftSlots.totalCount,
    },
  })

  const { editShift } = useEditShiftMutation()

  const onSubmit = async ({ startTime, endTime, roleName, volunteersRequired }: ShiftInput) => {
    function convertToDateTimeString(timeString: string, originalDate: string): string | null {
      const originalDateTime = timeFormatter.fromISO(originalDate).dateTime
      const [hours, minutes] = timeString.split(':').map((num) => parseInt(num, 10))

      const newDate = originalDateTime.set({ hour: hours, minute: minutes })

      return newDate.toISO()
    }

    const shiftStartTime = convertToDateTimeString(startTime, shift.startTime)
    const shiftEndTime = convertToDateTimeString(endTime, shift.endTime)

    await editShift({
      id: shift.id,
      startTime: shiftStartTime,
      endTime: shiftEndTime,
      roleName,
      volunteersRequired,
      draft: true,
      onSuccess,
      setFormErrors,
    })
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button rank="link" ariaLabel={t('edit_shift')}>
          <Icon type="pencil-02" />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <AppErrorsAlert errorsPath="editShift" />
        <Dialog.Header>
          <Dialog.Title>{t('edit_shift')}</Dialog.Title>
        </Dialog.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
          <EditShiftFieldRow
            register={register}
            control={control}
            errors={errors}
            currentAssignedSlots={shift.shiftSlots.assignedCount}
          />
          <Alert type="info">
            <p>{t('draft_mode_notification.part_1')}</p>
            <p>{t('draft_mode_notification.part_2')}</p>
          </Alert>
          <Dialog.Footer>
            <Dialog.Close> {t('cancel')} </Dialog.Close>
            <Button type="submit" trailingIcon="arrow-circle-broken-right">
              {t('save_shift')}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}
