import { Button, TextInput } from '@atoms'
import Icon from '@components/utils/Icon'

import { scopedTranslation } from '@frontend/utils/I18n'

type AddShiftFieldRowProps = {
  // TODO: Why did Nathan leave `any` here? Fix this type
  register: any
  errors: any
  index: number
  onRemove: () => void
  canRemove: boolean
}

const t = scopedTranslation('components.add_shifts')
const tAttributes = scopedTranslation('attributes.shift')

export const AddShiftFieldRow = ({ register, errors, index, onRemove, canRemove }: AddShiftFieldRowProps) => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-start tw-align-content-start tw-gap-2">
      <div className="tw-flex tw-items-start tw-gap-4">
        <TextInput
          displayName={tAttributes('start_time')}
          {...register(`shifts.${index}.startTime` as const)}
          type="time"
          errors={errors}
        />
        <TextInput
          displayName={tAttributes('end_time')}
          {...register(`shifts.${index}.endTime` as const)}
          type="time"
          errors={errors}
        />
        <TextInput
          displayName={tAttributes('role')}
          {...register(`shifts.${index}.roleName` as const)}
          type="text"
          errors={errors}
        />
        <TextInput
          displayName={tAttributes('volunteers_required')}
          {...register(`shifts.${index}.volunteersRequired` as const)}
          type="text"
          min={1}
          errors={errors}
        />
        <div
          // Trying to center the button with and without errors.
          // Couldnt think of another way but open to suggestions
          className={`tw-flex tw-flex-col tw-h-full ${errors?.shifts && errors.shifts[index] ? 'tw-justify-center' : 'tw-justify-end tw-pb-[2px]'}`}
        >
          <Button
            type="button"
            onClick={onRemove}
            rank="secondary"
            color="danger"
            size="sm"
            aria-label={'Remove shift'}
            disabled={!canRemove}
          >
            <Icon type="trash" />
          </Button>
        </div>
      </div>
    </div>
  )
}
