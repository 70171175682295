import { UseFormRegister, FieldErrors, Control, useFieldArray } from 'react-hook-form'
import { Button } from '@atoms'
import { AddShiftFieldRow } from './AddShiftFieldRow'
import timeFormatter from '@frontend/utils/timeFormatter'

import type { ShiftsArray } from './AddShiftsDialog'
import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components.add_shifts')

type AddShiftRowsControlProps = {
  register: UseFormRegister<ShiftsArray>
  errors: FieldErrors<ShiftsArray>
  control: Control<ShiftsArray>
  eventStartTime: string
  eventEndTime: string
}

export const AddShiftRowsControl = ({
  register,
  errors,
  control,
  eventStartTime,
  eventEndTime,
}: AddShiftRowsControlProps) => {
  const {
    fields: shifts,
    append,
    remove,
  } = useFieldArray<ShiftsArray>({
    control,
    name: 'shifts',
  })

  const handleAddShift = () => {
    const defaultShift = {
      startTime: timeFormatter.fromISO(eventStartTime).to24hours(),
      endTime: timeFormatter.fromISO(eventEndTime).to24hours(),
      roleName: '',
      draft: true,
      volunteersRequired: 1,
    }

    append(defaultShift)
  }

  const handleRemoveShift = (index: number) => {
    if (shifts.length > 1) {
      remove(index)
    }
  }

  return (
    <div className="tw-flex tw-flex-col tw-justify-between tw-gap-4">
      {shifts.map((field, index) => (
        <AddShiftFieldRow
          key={field.id}
          register={register}
          errors={errors}
          index={index}
          onRemove={() => handleRemoveShift(index)}
          canRemove={shifts.length > 1}
        />
      ))}
      <div className="tw-flex-1">
        <Button trailingIcon="plus-circle" onClick={handleAddShift} type="button" size="sm">
          {t('add_another_shift')}
        </Button>
      </div>
    </div>
  )
}
