import { StateCreator } from 'zustand'
import useEventsSearch from '../../shared/hooks/useEventsSearch'
import { EventsFilteringStore } from './useEventsFilteringStore'

export type SortStoreState = {
  currentSort?: string,
}

export type SortStoreActions = {
  setCurrentSort: (newSort: string) => void,
  resetSort: () => void
}

export type SortSlice = SortStoreState & SortStoreActions

export const createSortSlice: StateCreator<
  EventsFilteringStore,
  [],
  [],
  SortSlice
> = (set, get, store) => ({
  setCurrentSort: (newSort) => {
    set({ currentSort: newSort }),
    useEventsSearch({
      currentSort: newSort,
      setLoading: get().setLoading
    })
  },
  resetSort: () => set({ currentSort: 'start_time asc' }),
})