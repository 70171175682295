import type { Scope, TranslateOptions } from 'i18n-js'
import i18n from './I18n-locales'

interface CustomTranslateOptions extends Omit<TranslateOptions, 'count'> {
  count?: number | string
}

const i18nScopeToString = (scope: Scope) => (Array.isArray(scope) ? scope.join('.') : scope.toString())

export function t(scope: Scope, options?: CustomTranslateOptions) {
  const scopeStr = i18nScopeToString(scope)
  return i18n.t(`v2.${scopeStr}`, options as TranslateOptions)
}

export function scopedTranslation(baseScope: Scope, options?: TranslateOptions) {
  return function (scope: Scope, opts = options) {
    const baseScopeStr = i18nScopeToString(baseScope)
    const scopeStr = i18nScopeToString(scope)
    return t(`${baseScopeStr}.${scopeStr}`, opts)
  }
}

type ValidationName = 'required' | 'valid' | 'too_short' | 'positive_number'

export const i18nValidation = (
  type: ValidationName,
  count?: number | string,
  options?: { transformLabel?: (label: string) => string }
) => {
  return ({ label, path }) => {
    const attributeName = options?.transformLabel ? options.transformLabel(label ?? path) : (label ?? path)

    if (type === 'too_short' && count !== undefined) {
      return t(`shared.validations.${type}`, { attribute_name: attributeName, count })
    }
    if (type === 'positive_number' && count !== undefined) {
      return t(`shared.validations.${type}`, { attribute_name: attributeName, count })
    }
    return t(`shared.validations.${type}`, { attribute_name: attributeName })
  }
}