import React from 'react'

import type { GroupConnection, Maybe } from '@frontend/graphql/types.generated'

import {Link} from '@atoms'
import Pagination from '@molecules/Pagination'
import LoadingWrapper from '@molecules/LoadingWrapper'
import SearchField, { searchFn } from '@molecules/SearchField'

import GroupCard, { type GroupCardProps } from '../features/list-groups/GroupIndexGroupCard'
import type { SharedInertiaProps } from './GroupShow'

import { scopedTranslation } from '@utils/I18n'
import EmptyState from "@molecules/EmptyState";
const t = scopedTranslation('group_index')
const tShared = scopedTranslation('shared')

type GroupsIndexProps = {
  allGroups: { totalCount: number }
  groups: Pick<GroupConnection, 'totalCount' | 'totalPages'> & {
    nodes: Maybe<Array<Maybe<GroupCardProps>>>
  }
} & SharedInertiaProps

function GroupsIndex({ allGroups, groups: groupsData, permissions }: GroupsIndexProps) {
  const [loading, setLoading] = React.useState(false)

  const { nodes: groups, totalCount: matchingGroupsCount, totalPages } = groupsData
  const allGroupsCount = allGroups.totalCount
  const partialReloadKeys = ['groups']
  const searchParamName = 'search'
  const searchValue = new URLSearchParams(window.location.search).get(searchParamName)

  return (
    <>
      <div className="tw-full tw-px-6 tw-py-4 tw-border-y border-gray-200">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="flex-grow-1 tw-max-w-xs">
            <SearchField
              fieldName={searchParamName}
              placeholder={t('search_groups')}
              displayName={t('search_groups')}
              onChange={(event) => {
                searchFn(event, partialReloadKeys, setLoading)
              }}
              dataCompId="groups_search-field"
            />
          </div>
          <Link
            className="tw-w-72"
            href="/groups/new"
            size="xl"
            rank="primary"
            trailingIcon="plus-circle"
            dataCompId={'groups_add-new-group'}
          >
            {tShared('nav_links.add_new_group')}
          </Link>
        </div>
      </div>
      <div className="tw-full tw-px-6 tw-py-4 tw-font-sans">
        {allGroupsCount === 0 ? (
            <EmptyState headline={t('empty_state.headline')} subtext={t('empty_state.subtext')} iconType={"info-circle"}>
              <Link
                className="tw-w-72"
                href="/groups/new"
                size="xl"
                rank="primary"
                trailingIcon="plus-circle"
                dataCompId={'groups_add-new-group'}
                ariaLabel={t('empty_state.button')}
              >
                {t('empty_state.button')}
              </Link>
            </EmptyState>
        ) : (
          <LoadingWrapper loading={loading}>
            <div className="tw-flex tw-flex-col tw-gap-4">
              {/* role="status" sets aria-live="polite" and aria-atomic="true" */}
              <div role="status" aria-busy={loading}>
                {(searchValue ?? loading) && (
                  <div className="tw-flex tw-items-center tw-bg-primary-50 tw-py-4 tw-px-6 tw-gap-2.5">
                    <p className="tw-text-primary-600">
                      {allGroupsCount === 0
                        ? t('no_groups_found')
                        : t('showing_x_groups', { count: matchingGroupsCount })}
                    </p>
                  </div>
                )}
              </div>
              <div className="tw-flex tw-flex-wrap tw-gap-4 tw-align-center tw-justify-center">
                {groups?.map((group, index) => {
                  if (!group) return null
                  return (
                    <GroupCard
                      {...group}
                      key={group?.id}
                      permissions={permissions}
                      dataCompId={`groups-info-card-${index}`}
                    />
                  )
                })}
                <Pagination totalPages={totalPages ?? 1} only={partialReloadKeys} />
              </div>
            </div>
          </LoadingWrapper>
        )}
      </div>
    </>
  )
}

export default GroupsIndex
