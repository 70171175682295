import React from 'react'
import clsx from 'clsx'
import Icon from '@components/utils/Icon'
import { type IconTypes } from '../utils/Icon'
import { type ButtonStyleProps, rankColorClasses, sizeClasses } from './ButtonStyles'

export type ButtonProps = ButtonStyleProps & {
  children: React.ReactNode
  trailingIcon?: IconTypes
  leadingIcon?: IconTypes
  type?: 'submit' | 'button' | 'reset'
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ariaLabel?: string
  dataCompId?: string
  role?: string
  linkBackground?: boolean
}

// eslint-disable-next-line react/display-name
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'md',
      rank = 'primary',
      color = 'primary',
      linkBackground = true,
      leadingIcon,
      trailingIcon,
      type = 'button',
      children,
      onClick,
      ariaLabel,
      disabled,
      dataCompId,
      ...props
    },
    ref
  ) => {
    return (
      <button
        disabled={disabled}
        aria-disabled={disabled}
        type={type}
        onClick={onClick}
        aria-label={ariaLabel}
        ref={ref}
        {...props}
        className={clsx(
          'tw-rounded-lg tw-transition',
          'tw-flex tw-items-center tw-gap-2',
          'focus-visible:tw-border-primary-500 focus-visible:tw-ring-4 focus-visible:tw-ring-primary-300',
          sizeClasses(size),
          rankColorClasses(rank, color, linkBackground)
        )}
        {...(dataCompId && { 'data-compid': `${dataCompId}-button` })}
      >
        {leadingIcon && (
          <Icon dataTestId="leading-icon" type={leadingIcon} className="tw-h-[1.125rem] tw-w-[1.125rem] tw-mt-0.5" />
        )}
        {children && (
          <span
            className={clsx(
              'tw-gap-2 tw-whitespace-nowrap tw-flex tw-flex-nowrap tw-items-center tw-justify-center',
              (leadingIcon ?? trailingIcon) ? 'tw-relative tw-top-[1px]' : ''
            )}
          >
            {children}
          </span>
        )}
        {trailingIcon && (
          <Icon dataTestId="trailing-icon" type={trailingIcon} className="tw-h-[1.125rem] tw-w-[1.125rem] tw-mt-0.5" />
        )}
      </button>
    )
  }
)

export default Button
