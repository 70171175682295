import React from 'react'
import clsx from 'clsx'

import { scopedTranslation } from '@utils/I18n'
import timeFormatter from '@frontend/utils/timeFormatter'

import { EditShiftDialog } from '../edit_shifts/EditShiftDialog'
import { DeleteShiftDialog } from '../delete_shifts/DeleteShiftDialog'

import type { ShiftWithSlots } from './ShiftsTable'

const tAttributes = scopedTranslation('attributes.shift')
const t = scopedTranslation('components.shift_card')

const DraftStateLabel = () => {
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-text-sm tw-bg-orange-500 tw-py-2 tw-text-white ">
      <p>{t('draft_state')}</p>
    </div>
  )
}

type ShiftCardProps = {
  shift: ShiftWithSlots
  onShiftUpdate: () => void
}
export const ShiftCard = ({ shift, onShiftUpdate }: ShiftCardProps) => {
  const shiftStartTime = timeFormatter.fromISO(shift.startTime).toHoursMinutes()
  const shiftEndTime = timeFormatter.fromISO(shift.endTime).toHoursMinutes()
  const isDraft = shift.draft

  return (
    <div data-testid="shift-card" className="tw-text-gray-900 tw-text-md">
      <div
        className={clsx(
          'tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center',
          'sm:tw-pr-2 sm:tw-pl-6 sm:tw-h-36',
          'tw-border tw-border-gray-300'
        )}
      >
        <div>
          <p>
            {shiftStartTime} - {shiftEndTime}
          </p>
          <p>
            {tAttributes('role')}: {shift.roleName}
          </p>
        </div>
        <div className="tw-flex">
          <EditShiftDialog shift={shift} onShiftUpdate={onShiftUpdate} />
          <DeleteShiftDialog id={shift.id} onShiftDeleted={onShiftUpdate} />
        </div>
      </div>
      {isDraft && <DraftStateLabel />}
    </div>
  )
}
