"use client"

import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"
import clsx from "clsx"

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & { progressColor?: string }
>(({ className, value, progressColor, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={clsx(
      "tw-relative tw-h-2 tw-w-full tw-overflow-hidden tw-rounded-full",
      className
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={clsx(
        "tw-h-full tw-w-full tw-flex-1 tw-transition-all",
        progressColor
      )}
      style={{ transform: `translateX(-${100 - (value ?? 0)}%)` }}
    />
  </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
