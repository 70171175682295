import { createContext, useContext } from 'react'
import { create } from 'zustand'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { createSortSlice, type SortStoreState, type SortStoreActions } from './sortStoreSlice'

export type StoreState = SortStoreState
export type StoreActions = SortStoreActions

export type EventsFilteringStore = StoreState & StoreActions & {
  loading: boolean,
  setLoading: (isLoading: boolean) => void
}

const createEventsFilteringStore = (initProps: Partial<EventsFilteringStore> = {}) => {
  return create<EventsFilteringStore>((set, get, store) => ({
    ...createSortSlice(set, get, store),
    loading: false,
    setLoading: (isLoading: boolean) => {
      set({ loading: isLoading })
    },
    ...initProps
  }))
}

const EventsFilteringContext = createContext<ReturnType<typeof createEventsFilteringStore> | null>(null)

export function EventsFilteringProvider({ children, initProps } : {
  children: React.ReactNode;
  initProps?: Partial<EventsFilteringStore>}
) {
  const store = createEventsFilteringStore(initProps)
  return (
    <EventsFilteringContext.Provider value={store}>
      {children}
    </EventsFilteringContext.Provider>
  )
}

export default function useEventsFilteringStore<U>(selector: (state: EventsFilteringStore) => U): U {
  const store = useContext(EventsFilteringContext)
  if (!store) {
    throw new Error('Missing EventsFilteringProvider in the tree')
  }
  return useStoreWithEqualityFn(store, selector, shallow)
}