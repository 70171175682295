import { Alert, TextInput } from '@atoms'
import { UseFormRegister, FieldErrors, useWatch, Control } from 'react-hook-form'

import { scopedTranslation } from '@frontend/utils/I18n'

const t = scopedTranslation('components.edit_shift')
const tAttributes = scopedTranslation('attributes.shift')

interface ShiftFormValues {
  startTime: string
  endTime: string
  roleName: string
  volunteersRequired: number
}

export type EditShiftFieldRowProps = {
  register: UseFormRegister<ShiftFormValues>
  errors: FieldErrors<ShiftFormValues>
  control: Control<ShiftFormValues>
  currentAssignedSlots: number
}

export const EditShiftFieldRow = ({ register, control, errors, currentAssignedSlots }: EditShiftFieldRowProps) => {
  const volunteersRequired = useWatch({
    control,
    name: 'volunteersRequired',
  })

  const volunteersRequiredFieldIsValid = ({ fieldValue }: { fieldValue: number }) => {
    return !isNaN(fieldValue) && fieldValue > 0
  }

  const showRemovingAssignedSlotsAlert = () => {
    if (!volunteersRequiredFieldIsValid({ fieldValue: volunteersRequired })) return false
    if (currentAssignedSlots === 0) return false

    return volunteersRequired < currentAssignedSlots
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-items-start tw-gap-4">
        <TextInput displayName={tAttributes('start_time')} {...register(`startTime`)} type="time" errors={errors} />
        <TextInput displayName={tAttributes('end_time')} {...register(`endTime`)} type="time" errors={errors} />
        <TextInput displayName={tAttributes('role')} {...register(`roleName`)} type="text" errors={errors} />
        <TextInput
          displayName={tAttributes('volunteers_required')}
          {...register(`volunteersRequired`)}
          type="text"
          min={1}
          errors={errors}
        />
      </div>

      {showRemovingAssignedSlotsAlert() && (
        <Alert
          type={'warning'}
          title={t('removing_assigned_slots_alert.current_assigned_slots_count', { count: currentAssignedSlots })}
        >
          {t('removing_assigned_slots_alert.advice')}
        </Alert>
      )}
    </div>
  )
}
