import { useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import useFormWithErrors from '@frontend/hooks/useFormWithErrors'
import type { Event, Shift } from '@frontend/graphql/types.generated'

import { Dialog, Button, Alert } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import { scopedTranslation, i18nValidation } from '@utils/I18n'
import timeFormatter from '@frontend/utils/timeFormatter'

import { AddShiftRowsControl } from './AddShiftRowsControl'
import useAddShiftsMutation from './useAddShiftsMutation'

type EventProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime'>
}

type AddShiftsDialogProps = EventProps & {
  onShiftUpdate: () => void
}

type ShiftInput = Pick<Shift, 'startTime' | 'endTime' | 'roleName' | 'draft'> & { volunteersRequired: number }

export type ShiftsArray = {
  shifts?: ShiftInput[]
}

const t = scopedTranslation('components.add_shifts')
const tAttributes = scopedTranslation('attributes.shift')
const tShiftsIndex = scopedTranslation('shifts_index')

const shiftSchema = yup.object({
  shifts: yup
    .array()
    .of(
      yup.object({
        startTime: yup.string().label(tAttributes('start_time')).required(i18nValidation('required')),
        endTime: yup.string().label(tAttributes('end_time')).required(i18nValidation('required')),
        roleName: yup.string().label(tAttributes('role')).required(i18nValidation('required')),
        volunteersRequired: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .label(tAttributes('volunteers'))
          .min(
            1,
            i18nValidation('positive_number', 'one', {
              transformLabel: (label) => label.toLowerCase(),
            })
          )
          .required(
            i18nValidation('positive_number', 'one', {
              transformLabel: (label) => label.toLowerCase(),
            })
          ),
      })
    )
    .min(1, 'Please submit at least one shift'),
})

function convertToDateTimeString(timeString: string, originalDate: string): string | null {
  const originalDateTime = timeFormatter.fromISO(originalDate).dateTime
  const [hours, minutes] = timeString.split(':').map((num) => parseInt(num, 10))

  const newDate = originalDateTime.set({ hour: hours, minute: minutes })

  return newDate.toISO()
}

function serializeShifts({
  shifts = [],
  eventStartTime,
  eventEndTime,
}: {
  shifts: ShiftInput[] | []
  eventStartTime: string
  eventEndTime: string
}) {
  return (
    shifts?.map((shift) => ({
      ...shift,
      startTime: convertToDateTimeString(shift.startTime, eventStartTime),
      endTime: convertToDateTimeString(shift.endTime, eventEndTime),
    })) || []
  )
}

export const AddShiftsDialog = ({ event, onShiftUpdate }: AddShiftsDialogProps) => {
  const { id: eventId, startTime: eventStartTime, endTime: eventEndTime } = event

  const [open, setOpen] = useState(false)

  const onSuccess = useCallback(() => {
    setOpen(false)
    onShiftUpdate()
  }, [onShiftUpdate])

  const formMethods = useFormWithErrors<ShiftsArray>({
    resolver: yupResolver(shiftSchema),
    mode: 'onSubmit',
    defaultValues: {
      shifts: [
        {
          startTime: timeFormatter.fromISO(eventStartTime).to24hours(),
          endTime: timeFormatter.fromISO(eventEndTime).to24hours(),
          roleName: '',
          volunteersRequired: 1,
          draft: true,
        },
      ],
    },
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setErrors: setFormErrors,
  } = formMethods

  const { addShifts } = useAddShiftsMutation()

  const onSubmit = async (data: ShiftsArray) => {
    const shifts = serializeShifts({ shifts: data.shifts ?? [], eventStartTime, eventEndTime })

    await addShifts(eventId, shifts, onSuccess, setFormErrors)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
    reset()
  }, [reset])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button size="xl">{tShiftsIndex('add_shifts')}</Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl" scroll>
        <AppErrorsAlert errorsPath="addShifts" />
        <Dialog.Header>
          <Dialog.Title>{t('new_shifts')}</Dialog.Title>
        </Dialog.Header>

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
            <AddShiftRowsControl
              register={register}
              errors={errors}
              control={control}
              eventStartTime={eventStartTime}
              eventEndTime={eventEndTime}
            />
            <Alert type="info">
              <p>{t('draft_mode_notification.part_1')}</p>
              <p>{t('draft_mode_notification.part_2')}</p>
            </Alert>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button type="button" onClick={handleClose} rank="link" color="danger">
                  {t('cancel')}
                </Button>
              </Dialog.Close>
              <Button type="submit" trailingIcon="arrow-circle-broken-right">
                {t('submit_shift')}
              </Button>
            </Dialog.Footer>
          </form>
        </FormProvider>
      </Dialog.Content>
    </Dialog.Root>
  )
}
