import { useCallback, useState } from 'react'
import { Dialog, Button } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import { scopedTranslation } from '@utils/I18n'
import usePartialReload from '@hooks/usePartialReload'

import useConfirmDraftRosterMutation from './useConfirmDraftRosterMutation'

import type { Event, Roster, Shift, ShiftConnection } from '@frontend/graphql/types.generated'

type PublishAllShiftsDialogProps = {
  event: Pick<Event, 'name' | 'startTime' | 'endTime'> & {
    roster:
      | (Pick<Roster, 'id'> & {
          shifts?: Pick<ShiftConnection, 'totalCount'> & {
            nodes: Array<Pick<Shift, 'id' | 'draft'>> | []
          }
        })
      | null
  }
}

const t = scopedTranslation('components.confirm_shifts')

const ConfirmDraftRosterDialog = ({ event }: PublishAllShiftsDialogProps) => {
  const [open, setOpen] = useState(false)
  const handleRosterUpdate = () => {
    usePartialReload('', ['event'], () => {})
  }

  const isEnabled = () => {
    if (!event.roster || !event.roster.shifts) return false
    return event.roster.shifts.nodes.some((shift: { draft: boolean }) => shift.draft)
  }

  const onSuccess = () => {
    setOpen(false)
    handleRosterUpdate()
  }

  const confirmRoster = useConfirmDraftRosterMutation()

  const handlePublish = async () => {
    await confirmRoster({ rosterId: event.roster!.id, onSuccess })
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <div className="tw-w-max">
          <Button size="xl" disabled={!isEnabled()}>
            {t('publish_all_shifts_title')}
          </Button>
        </div>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <AppErrorsAlert errorsPath="confirmRoster" />
        <Dialog.Header>
          <Dialog.Title>{t('publish_all_shifts_title')}</Dialog.Title>
        </Dialog.Header>
        <p>{t('confirm_and_publish_changes_helper_text')}</p>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button type="button" onClick={handleClose} rank="link" color="danger">
              {t('cancel')}
            </Button>
          </Dialog.Close>
          <Button type="button" onClick={handlePublish}>
            {t('publish')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default ConfirmDraftRosterDialog
