import { ShiftCard } from './ShiftCard'
import clsx from 'clsx'

import type { ShiftSlotWithShift, ShiftWithSlots } from './ShiftsTable'

import ShiftSlotCard from './ShiftSlotCard'

const sortAssignedShiftSlots = (slots: ShiftSlotWithShift[]) => {
  return slots.sort((a, b) => {
    const isAAssigned = Boolean(a.supporter?.fullName)
    const isBAssigned = Boolean(b.supporter?.fullName)
    return isBAssigned === isAAssigned ? 0 : isAAssigned ? -1 : 1
  })
}

type ShiftRowProps = {
  shift: ShiftWithSlots
  index: number
  handleShiftUpdate: () => void
}
const ShiftRow = ({ shift, index, handleShiftUpdate }: ShiftRowProps) => {
  const shiftSlots = shift.shiftSlots.nodes ?? []
  const sortedSlots = sortAssignedShiftSlots(shiftSlots)

  return (
    <div className="tw-flex tw-gap-4">
      <div key={shift.id} className="tw-w-[10rem] sm:tw-w-[21rem]">
        <ShiftCard shift={shift} onShiftUpdate={handleShiftUpdate} />
      </div>
      <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-gap-4">
        {sortedSlots.map((slot: ShiftSlotWithShift) => (
          <ShiftSlotCard slot={slot} onShiftUpdate={handleShiftUpdate} key={slot.id} />
        ))}
      </div>
    </div>
  )
}

export default ShiftRow
