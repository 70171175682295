import React from 'react'
import clsx from 'clsx'

type CardProps = {
  children: React.ReactNode
  className?: string
  backgroundFill?: boolean
  title: string
  description?: string
  dataCompId?: string
}

export default function Card({ children, className, backgroundFill = false, title, description, dataCompId }: CardProps) {
  return (
    <section
      className="tw-w-full tw-min-h-[50vh]  tw-bg-white tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-rounded-lg tw-border-t-0"
      {...(dataCompId && { 'data-compid': dataCompId })}
    >
      <Header title={title} description={description} backgroundFill={backgroundFill} />
      <main className={`tw-flex tw-flex-col ${className ?? ''}`}>{children}</main>
    </section>
  )
}

type HeaderProps = {
  title: string
  description?: string
  backgroundFill: boolean
}

function Header({ title, description, backgroundFill }: HeaderProps) {
  return (
    <header
      className={clsx(
        'tw-border-b tw-border-gray-100 tw-h-24',
        backgroundFill && 'tw-bg-gradient-to-b tw-from-primary-700 tw-to-primary-600'
      )}
    >
      {!backgroundFill && <div className="tw-bg-gradient-to-b tw-from-primary-700 tw-to-primary-600 tw-h-2"></div>}
      <div className={clsx('tw-px-6 tw-py-5 tw-h-full tw-flex tw-items-center', !backgroundFill && 'tw-pt-3')}>
        <div>
          <h2
            className={clsx(
              'tw-text-lg tw-font-medium',
              backgroundFill && 'tw-text-white',
              !backgroundFill && 'tw-text-gray-900'
            )}
          >
            {title}
          </h2>
          {description && (
            <p className="tw-text-sm tw-text-gray-500" data-testid="card-header-description">
              {description}
            </p>
          )}
        </div>
      </div>
    </header>
  )
}
