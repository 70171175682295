import gql from 'graphql-tag';

export const addShiftMutation = gql`
  mutation AddShift($input: AddShiftsInput!) {
    addShifts(input: $input) {
      roster {
        id
        shifts {
          nodes {
            id
            startTime
            endTime
            roleName
            draft
          }
        }
      }
      errors {
        message
        code
        field
      }
    }
  }
`
