import useMutation from '@hooks/useGQLMutation'
import { useErrorsStore } from '@stores/errorsStore'

import { scopedTranslation } from '@frontend/utils/I18n'

import { unassignShiftSlotMutation } from './mutations'
import type { UnassignShiftSlotDocument } from '@frontend/graphql/types.generated'

import type { ShiftSlotWithShift } from '../shifts/ShiftsTable'

type UnassignShiftValues = {
  id: ShiftSlotWithShift['id']
  onSuccess: () => void
}

const t = scopedTranslation('components.unassign_shift')

const useUnassignShiftSlotMuation = () => {
  const { setErrors: setAppErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof UnassignShiftSlotDocument>(unassignShiftSlotMutation)

  const unassignShiftSlot = async ({ id, onSuccess }: UnassignShiftValues) => {
    clearErrors('unassignShift')
    mutate(
      {
        input: {
          id: id,
        },
      },
      {
        onSuccess: (response) => {
          if (response?.unassignShiftSlot?.shiftSlot?.id) {
            onSuccess()
          } else {
            setAppErrors({
              errorsPath: 'unassignShift',
              errors: response?.unassignShiftSlot?.errors ?? [
                {
                  code: 'UNASSIGN_SHIFT_ERROR',
                  message: t('errors.unable_to_unassign_shift_error'),
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'unassignShift',
            errors: [
              {
                code: 'UNASSIGN_SHIFT_ERROR',
                message: t('errors.unable_to_unassign_shift_error'),
              },
            ],
          })
        },
      }
    )
  }

  return unassignShiftSlot
}

export default useUnassignShiftSlotMuation
