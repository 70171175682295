import gql from 'graphql-tag'

export const ConfirmRosterMutation = gql`
  mutation ConfirmRoster($input: ConfirmRosterInput!) {
    confirmRoster(input: $input) {
      roster {
        id
        shifts {
          totalCount
          nodes {
            id
            draft
          }
        }
      }
      success
      errors {
        code
        message
        field
      }
    }
  }
`
