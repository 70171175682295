import gql from 'graphql-tag'

export const unassignShiftSlotMutation = gql`
  mutation unassignShiftSlot($input: UnassignShiftSlotInput!) {
    unassignShiftSlot(input: $input) {
      shiftSlot {
        id
      }
      success
      errors {
        field
        message
        code
      }
    }
  }
`
