import type { ShiftInput, EditShiftDocument } from '@frontend/graphql/types.generated'

import useMutation from '@hooks/useGQLMutation'
import { useErrorsStore, type ErrorType } from '@stores/errorsStore'
import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { editShiftMutation } from './mutations'

type EditShiftProps = ShiftInput & {
  id: string
  onSuccess: () => void
  setFormErrors: (errors: any) => void
}

export const useEditShiftMutation = () => {
  const { setErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof EditShiftDocument>(editShiftMutation)

  const editShift = async ({
    id,
    startTime,
    endTime,
    roleName,
    volunteersRequired,
    onSuccess,
    setFormErrors,
  }: EditShiftProps) => {
    clearErrors('editShift')
    mutate(
      {
        input: {
          id,
          shift: {
            startTime,
            endTime,
            roleName,
            volunteersRequired,
            draft: true,
          },
        },
      },
      {
        onSuccess: (response) => {
          if (response?.editShift?.shift) {
            onSuccess()
          } else {
            handleErrors(
              (response?.editShift?.errors as ErrorType[]) || [
                { message: 'An unknown error occurred', code: 'unknown' },
              ],
              setFormErrors
            )
          }
        },
        onError: () => {
          handleErrors([{ message: 'An unknown error occurred', code: 'unknown' }], setFormErrors)
        },
      }
    )
  }

  const handleErrors = (errors: ErrorType[], setFormErrors: (errors: any) => void) => {
    setErrors({ errorsPath: 'editShift', errors })
    setFormErrors({
      errorsBasePath: 'shifts',
      errors,
      adapter: serverErrorsToFormErrors,
    })
  }

  return { editShift }
}
