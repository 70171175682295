import usePartialReload from '@hooks/usePartialReload'

import { scopedTranslation } from '@utils/I18n'

import ShiftRow from './ShiftRow'
import { AddShiftsDialog } from '../add_shifts/AddShiftsDialog'
import type {
  Event,
  Roster,
  Shift,
  ShiftConnection,
  ShiftSlotsConnection,
  ShiftSlot,
  Supporter,
  Maybe,
} from '@frontend/graphql/types.generated'

const t = scopedTranslation('shifts_index')

const sortShifts = (a: ShiftWithSlots, b: ShiftWithSlots): number => {
  const aStartTime = new Date(a.startTime).getTime()
  const bStartTime = new Date(b.startTime).getTime()
  const aEndTime = new Date(a.endTime).getTime()
  const bEndTime = new Date(b.endTime).getTime()

  if (aStartTime !== bStartTime) {
    return aStartTime - bStartTime
  }

  if (aEndTime !== bEndTime) {
    return aEndTime - bEndTime
  }

  return a.roleName.localeCompare(b.roleName)
}

export type ShiftSlotWithShift = Pick<ShiftSlot, 'id'> & {
  shift: Pick<Shift, 'id' | 'startTime' | 'endTime' | 'roleName'>
  supporter: Maybe<Pick<Supporter, 'id' | 'firstName' | 'fullName'>>
}

export type ShiftWithSlots = Pick<Shift, 'id' | 'roleName' | 'startTime' | 'endTime' | 'draft'> & {
  shiftSlots: Pick<ShiftSlotsConnection, 'totalCount' | 'assignedCount'> & {
    nodes: Array<ShiftSlotWithShift> | []
  }
}

type ShiftsTableProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime'> & {
    roster:
      | (Pick<Roster, 'id'> & {
          shifts?: Pick<ShiftConnection, 'totalCount'> & {
            nodes: Array<ShiftWithSlots> | []
          }
        })
      | null
  }
}

export const ShiftsTable = ({ event }: ShiftsTableProps) => {
  const handleShiftUpdate = () => {
    usePartialReload('', ['event'], () => {})
  }

  const shifts = (event.roster?.shifts?.nodes ?? []).filter((shift): shift is ShiftWithSlots => shift !== null)

  return (
    <div className="tw-flex tw-flex-col tw-touch-auto tw-pr-4 sm:tw-gap-4 sm:tw-rounded-xl md:tw-overflow-auto sm:tw-p-0">
      <div className="tw-flex">
        <div className="tw-w-full tw-text-gray-900 tw-font-medium tw-h-12 tw-border-r-2 tw-border-r-gray-200 sm:tw-w-[21rem]">
          <div className="tw-flex tw-h-full">
            <div className="tw-bg-gray-100 tw-w-full tw-px-6 tw-py-3 sm:tw-rounded-tl-lg">{t('shifts')}</div>
          </div>
        </div>
        <div className="tw-h-12 tw-flex-grow tw-hidden sm:tw-block">
          <div className="tw-flex tw-h-full">
            <div className="tw-bg-gray-100 tw-h-full tw-w-full tw-px-6 tw-py-3 tw-rounded-tr-lg"></div>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-gap-8">
        <div className="tw-flex tw-flex-col tw-gap-4">
          {[...shifts].sort(sortShifts).map((shift, index) => (
            <ShiftRow key={shift.id} shift={shift} index={index} handleShiftUpdate={handleShiftUpdate} />
          ))}
        </div>

        <div className="tw-flex tw-items-center tw-justify-center tw-w-[10rem] sm:tw-w-[21rem]">
          <AddShiftsDialog event={event} onShiftUpdate={handleShiftUpdate} />
        </div>
      </div>
    </div>
  )
}
