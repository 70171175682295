import { useGraphQLClient } from '@utils/GraphQLClientProvider'
import { gql } from 'graphql-tag'

function useSupporterSearchQuery() {
  const client = useGraphQLClient()

  const SUPPORTERS_QUERY = gql`
    query getSupporters($groupId: ID!, $fullNameICont: String!, $first: Int!, $sortBy: String!) {
      group(id: $groupId) {
        supporters(fullNameICont: $fullNameICont, first: $first, sortBy: $sortBy) {
          totalCount
          nodes {
            id
            fullName
          }
        }
      }
    }
  `

  type SupporterNode = {
    id: string
    fullName: string
  }

  type SupporterNodes = {
    group: {
      supporters: {
        totalCount: number
        nodes: SupporterNode[]
      }
    }
  }

  const querySupporter = async ({
    nameInput,
    groupId,
  }: {
    nameInput: string
    groupId: string
  }): Promise<{
    totalCount: number
    supporters: Array<{
      id: string
      value: string
      label: string
    }>
  }> => {
    const results = await client.request<SupporterNodes>(SUPPORTERS_QUERY, {
      groupId: groupId,
      fullNameICont: nameInput,
      first: 7,
      sortBy: 'full_name asc',
    })

    const totalCount = results.group.supporters.totalCount
    const supporters = results.group.supporters.nodes

    const supporterInputs = supporters.map((supporter) => ({
      id: supporter.id,
      value: supporter.fullName,
      label: supporter.fullName,
    }))

    return {
      totalCount,
      supporters: supporterInputs,
    }
  }

  return { querySupporter }
}

export default useSupporterSearchQuery
