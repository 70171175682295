import { useGraphQLClient } from '@utils/GraphQLClientProvider'
import { useQuery as useReactQueryQuery, type QueryFunction, type QueryKey } from '@tanstack/react-query'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { type Variables } from 'graphql-request'

type useQueryProps = {
  // TODO: Remove all existing string key usave
  // and change the prop name from key to keys:
  key: string | QueryKey
  query: TypedDocumentNode<unknown, Variables>
  variables?: Variables
  maxAttempts?: number
}

export default function useQuery<Tdata>({ key, query, variables = {}, maxAttempts = 4 }: useQueryProps) {
  const client = useGraphQLClient()
  const queryFn: QueryFunction<Tdata> = async () => {
    const data = await client.request<Tdata>(query, variables)
    return data
  }

  return useReactQueryQuery({
    retry: (failureCount) => failureCount < maxAttempts,
    queryKey: Array.isArray(key) ? key : [key],
    queryFn,
  })
}
