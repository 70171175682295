import useMutation from '@hooks/useGQLMutation';
import { useErrorsStore, type ErrorType } from '@stores/errorsStore';
import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter';
import type { Event, AddShiftsInput, AddShiftDocument } from '@frontend/graphql/types.generated'

import { addShiftMutation } from './mutations'
import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components.add_shifts')

const useAddShiftsMutation = () => {
  const { setErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof AddShiftDocument>(addShiftMutation)

  const addShifts = async (
    eventId: AddShiftsInput['eventId'],
    shifts: AddShiftsInput['shifts'],
    onSuccess: () => void,
    setFormErrors: (errors: any) => void
  ) => {
    clearErrors('addShift')
    mutate(
      {
        input: {
          eventId,
          shifts,
        },
      },
      {
        onSuccess: (response) => {
          if (response?.addShifts?.roster) {
            onSuccess()
          } else {
            setErrors({
              errorsPath: 'addShifts',
              errors: response?.addShifts?.errors ?? [
                {
                  code: 'ADD_SHIFTS_ERROR',
                  message: t('fallback_shift_error'),
                },
              ],
            })
          }
        },
        onError: () => {
          setErrors({
            errorsPath: 'addShifts',
            errors: [
              {
                code: 'ADD_SHIFTS_ERROR',
                message: t('fallback_shift_error'),
              },
            ],
          })
        },
      }
    )
  }

  return { addShifts }
}

export default useAddShiftsMutation
